<template>
  <div
    class="newsletter"
    :class="[
      'bg-color--' + backgroundColor
    ]"
    :style="[
      backgroundColorCss,
      foregroundColorCss
    ]"
  >
    <div class="content di di--1">
      <div class="sb-content">
        <div
          v-if="headline"
          class="headline"
          :class="headlineSize"
          v-html="nl2br(headline.html)"
        />
        <div
          v-if="text"
          class="text"
          v-html="text.html"
        />
      </div>
      <client-only>
        <div class="klaviyoform" :class="id" />
      </client-only>
    </div>
  </div>
</template>

<script>
import ColorHexCode from '~/mixins/ColorHexCode.js'
import { nl2br } from '~/lib/helpers'

export default {
  name: 'KlaviyoForm',
  mixins: [ColorHexCode],
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    headline: {
      type: Object,
      required: false,
      default: null
    },
    headlineSize: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: Object,
      required: false,
      default: null
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },
    styleModifier: {
      type: String,
      required: false,
      default: 'default'
    },
    foregroundColor: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      email: '',
      loading: false
    }
  },
  computed: {
    foregroundColorCss () {
      return { '--foreground-color': this.foregroundColor }
    },
    backgroundColorCss () {
      const backgroundColor = this.color(this.backgroundColor, this.backgroundColorHex)
      return backgroundColor ? { backgroundColor } : ''
    }
  },
  methods: {
    nl2br
  }
}
</script>

<style lang="scss" scoped>
.newsletter {
  padding: 2.4rem;
  color: var(--foreground-color, black);
  .content {
    .headline {
      margin: 0;
      &::v-deep {
        > br:last-of-type {
          display: none;
        }
        em {
          -webkit-text-stroke-color: var(--foreground-color, black);
        }
        p {
          color: var(--foreground-color, black);
          margin: 0;
        }
      }
    }
    .text {
      @include p--small;
      padding-bottom: 1.6rem;
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .newsletter {
    .content {
      .text {
        @include p--medium;
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .newsletter {
    padding: 4rem;
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 4.8rem;
      align-items: center;
      .headline,
      .text,
      .headline {
        line-height: 100%;
      }
      .text {
        padding-bottom: 0;
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .newsletter {
    .content {
      grid-gap: 10rem;
      .text {
        &::v-deep p {
          @include p--large;
          margin: 0 !important;
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $desktop) {
  .newsletter {
    .content {
      .text {
        padding: 3.2rem 0;
      }
    }
  }
}
</style>
